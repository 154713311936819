<template>
  <div>
    <toolbar-berea></toolbar-berea>

    <v-main>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    
    <footer-berea></footer-berea>
    
    <scroll-to-top></scroll-to-top>

  </div>
</template>

<script>
import ToolbarBerea from '@/components/ui/landing/toolbar/ToolbarBerea.vue'
import FooterBerea from '@/components/ui/landing/footer/FooterBerea.vue'
import ScrollToTop from '@/components/navigation/ScrollToTop.vue'
export default {
  components: {
    ToolbarBerea,
    FooterBerea,
    ScrollToTop
  }
}
</script>