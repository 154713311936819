export const menu1 = [{
  title: 'Solutions'
}, {
  title: 'Pricing'
}, {
  title: 'Docs'
}, {
  title: 'More'
}]

export const menu2 = [{
  title: 'HOME',
  href: '/'
}, {
  title: 'ABOUT US',
  href: '/about'
}, {
  title: 'RESOURCES',
  href: '/resources'
}, {
  title: 'CONTACT US',
  href: '/contact'
}, {
  title: 'LIVE STREAM',
  href: '/watch'
}, {
  title: 'ONLINE GIVING',
  href: 'onlinegiving'
}]

export const menu3 = [
  { key: 'menu.home', text: 'HOME', link: '/'  },
  { key: 'menu.about', text: 'ABOUT US', link: '/about',
    children: [
      { text: 'BELIEFS', link: '/about' },
      { text: 'CHURCH HISTORY', link: '/history' },
      { text: 'ABOUT PASTOR', link: '/pastor' }
    ] },
  { key: 'menu.services', text: 'SERVICES', link: '/services',
    children: [
      { text: 'CHURCH SERVICES', link: '/services' },
      { text: 'CALENDAR', link: '/calendar' },
      { text: 'MINISTRIES', link: '/ministries', 
        children: [
          { text: 'YOUTH & CHILDREN MINISTRIES', link: '/services/ministries' }
        ] }
    ] },
  { key: 'menu.bulletin', text: 'BULLETINS & NEWS', link: '/bulletins'  },
  { key: 'menu.watch', text: 'LIVE STREAM', link: '/watch'  },
  { key: 'menu.onlineGiving', text: 'ONLINE GIVING', href: 'https://adventistgiving.ca/#/org/AN6MIQ/envelope/start', target: '_blank' },
  { key: 'menu.contact', text: 'CONTACT US', link: '/contact'  }
]

export default {
  menu1,
  menu2,
  menu3
}
